/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import { Fade as FadeSlideshow } from 'react-slideshow-image';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { IMAGE_QUALITIES } from '../../scripts/constants';
import { handleImageUrls, isBrowser } from '../../scripts/utils';
import {
  GalleryWrap,
  Image,
  LeftArrow,
  StyledGallery,
  LoadingCard,
  Note,
  Arrows,
  RightArrow,
} from './styles';

const slideshowConfig = {
  duration: 5000,
  transitionDuration: 700,
  infinite: true,
  indicators: false,
  pauseOnHover: true,
  arrows: false,
};

const SRLOptions = {
  buttons: {
    showDownloadButton: false,
    showThumbnailsButton: false,
  },
  settings: {
    disablePanzoom: true,
    hideControlsAfter: 1000,
    slideAnimationType: 'both',
  },
  thubmnails: {
    showThumbnails: false,
  },
  caption: {
    showCaption: true,
  },
};

const Gallery = ({ photos = [], loading }) => {
  const { openLightbox } = useLightbox();
  const slideRef = useRef(null);
  const clickCount = useRef(0);
  const CLICK_INTERVAL = 300;
  let singleClickTimer = null;

  const navigate = (direction = 'right') => {
    if (direction === 'right') return slideRef.current.goNext();
    if (direction === 'left') return slideRef.current.goBack();
    return false;
  };

  const handleDoubleClick = (direction = 'right', index) => {
    clickCount.current += 1;
    if (clickCount.current === 1) {
      singleClickTimer = setTimeout(() => {
        clickCount.current = 0;
        navigate(direction);
      }, CLICK_INTERVAL);
    } else if (clickCount.current === 2) {
      openLightbox(index);
      clearTimeout(singleClickTimer);
      clickCount.current = 0;
    }
  };

  const photosList = photos.map(img => ({
    src: handleImageUrls(img.main_photo.url),
  }));

  if (loading) {
    return (
      <StyledGallery>
        <LoadingCard />
      </StyledGallery>
    );
  }

  return (
    <StyledGallery>
      {/* <button type="button" onClick={() => openLightbox()}>OPEN</button> */}
      {/* <Logger content={() => (<span>{data}</span>)} /> */}
      <GalleryWrap>
        {(isBrowser && photos?.length > 0) ? (
          <FadeSlideshow
            ref={node => { slideRef.current = node; }}
            {...slideshowConfig}
          >
            {photos?.map(({ id, main_photo: mainPhoto, name }, index) =>
              (
                <>
                  <Image key={id} src={handleImageUrls(mainPhoto?.formats?.[IMAGE_QUALITIES.LARGE].url)} alt="" onClick={() => openLightbox(index)} />
                  <Note>{name}</Note>
                  <Arrows>
                    <LeftArrow onClick={() => handleDoubleClick('left', index)} />
                    <RightArrow onClick={() => handleDoubleClick('right', index)} />
                  </Arrows>
                </>
              ))}
          </FadeSlideshow>
        ) : null}
        <SRLWrapper className="thumbnails" elements={photosList} options={SRLOptions} />
      </GalleryWrap>
    </StyledGallery>
  );
};

export default Gallery;
