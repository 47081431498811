import styled from 'styled-components';
import MagicCard from '../MagicCard';
import back from '../../images/back_16.png';
import next from '../../images/next_16.png';

export const LoadingCard = styled(MagicCard)`
  max-width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  background: linear-gradient(311deg, #EEE 30%, #CFD5DA, #EEE 70%) 0% 0% / 300% 300%;
  animation: shine 1.0s ease 0s infinite alternate none running;

  @keyframes shine {
    0%  {background-position:0% 50%;}
    100% {background-position:100% 50%;}
  }
`;

export const StyledGallery = styled.div`
  width: 100%;
  height: 100%;

  .react-slideshow-container {
    width: 100%;
    height: calc(100vh - (65px * 2));

    .react-slideshow-fade-wrapper {
      height: 100%;

      .react-slideshow-fade-images-wrap {
        height: 100%;
      }
    }

    .react-slideshow-fade-images-wrap {
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: auto;
        height: 100%;
      }
    }
  }
`;

export const Note = styled.p`
  width: 100%;
  margin: 0;
  padding: 0.5em;

  color: black;
  font-weight: normal;
  text-align: center;
  line-height: 1em;
`;

export const GalleryWrap = styled.div``;

export const Arrows = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 2;
`;

export const LeftArrow = styled.div`
  flex-basis: 100%;
   cursor: url(${back}), auto;
`;

export const RightArrow = styled.div`
  flex-basis: 100%;
  cursor: url(${next}), auto;
`;

export const Image = styled.img.attrs(({ src }) => ({ src }))`
  width: auto;
  height: calc(100% - 2em);
  max-height: 600px;
`;
