import React from 'react';
import styled from 'styled-components';
import SimpleReactLightbox from 'simple-react-lightbox';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import SlideGallery from '../components/SlideGallery/SlideGallery';
import usePhotos from '../hooks/usePhotos';

const Home = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 3 / span 8;
  height: 100%;
`;

const IndexPage = () => {
  const { photos, loading } = usePhotos({ showInHome: true });

  return (
    <SimpleReactLightbox>
      <Layout>
        <SEO title="Home" />
        <Home>
          <SlideGallery photos={photos} loading={loading} />
        </Home>
      </Layout>
    </SimpleReactLightbox>
  );
};

export default IndexPage;
