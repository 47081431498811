import React from 'react';
import styled from 'styled-components';

/* These are the card's highest layer's styles */
const DefinitiveWrapper = styled.div`
  position: relative;
  top: 0px;
  width: 100%;
  max-width: 425px;
  max-width: ${({ maxWidth }) => (typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth)};
  border-radius: 0px;

  color: #fff;
  background-image: ${({ image }) => (image ? `url(${image})` : '')};
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

/* This keeps the aspect ratio */
const MagicWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding: 0;
  padding-bottom: ${({ heightRatio }) => heightRatio}%;
`;

/* This creates a solid ground to place new elements */
const Foundations = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  ${({ innerStyle }) => innerStyle};
`;

const MagicCard = ({
  children = null,
  className = '',
  image = '',
  onClick,
  width = 280,
  maxWidth = 406,
  heightRatio = 66.75,
  style,
  innerStyle,
}) => (
  <DefinitiveWrapper
    image={image}
    className={className}
    onClick={onClick}
    width={width}
    maxWidth={maxWidth}
    style={style}
  >
    <MagicWrapper heightRatio={heightRatio}>
      <Foundations className="foundations" innerStyle={innerStyle}>
        {children}
      </Foundations>
    </MagicWrapper>
  </DefinitiveWrapper>
);

export default MagicCard;

